import React, { useState } from "react"
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import { Menu, MenuOpen } from "@material-ui/icons"
import { Link } from "gatsby"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: theme.palette.text.primary,
    },
    drawerPaper: {
      width: "100%",
    },
    iconButton: {
      float: "right",
      justifyContent: "flex-end",
    },
    menuButton: {
      color: theme.palette.primary.main,
      margin: theme.spacing(1),
      fontSize: "2rem",
      "& .MuiButton-startIcon": {
        margin: 0,
      },
      [theme.breakpoints.up("xs")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "3rem",
      },
    },
  })
)

const SideDrawer = ({ navLinks, ...props }) => {
  const classes = useStyles(props)
  const [state, setState] = useState({ right: false })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ [anchor]: open })
  }

  const sideDrawerList = anchor => (
    <React.Fragment>
      <div>
        <IconButton
          className={classes.iconButton}
          edge="start"
          aria-label="menu"
          onClick={toggleDrawer("right", false)}
        >
          <MenuOpen className={classes.menuButton} />
        </IconButton>
      </div>
      <div
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List component="nav">
          {navLinks.map(({ title, path }) => (
            <Link to={path} key={title} className={classes.linkText}>
              <ListItem button>
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    color: "textSecondary",
                    variant: "h3",
                  }}
                  secondaryTypographyProps={{
                    color: "textSecondary",
                    variant: "h3",
                  }}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer("right", true)}
      >
        <Menu className={classes.menuButton} />
      </IconButton>

      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {sideDrawerList("right")}
      </Drawer>
    </React.Fragment>
  )
}

export default SideDrawer
