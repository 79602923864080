import React from 'react'
import RootState from '../../store'
import { setTheme } from '../../store/reducers/themeSlice'
import Brightness3Icon from '@material-ui/icons/Brightness4'
import Brightness7Icon from '@material-ui/icons/Brightness7'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core/styles'
import {
  useAppSelector,
  useAppDispatch,
} from '../../store/hooks'

const ThemeToggle = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const state = useAppSelector(
    (state: typeof RootState) => state.persistedReducer,
  )
  const isLit = state.theme.value

  const themeHandle = () => {
    dispatch(setTheme())
    const element = document.body
    element.classList.toggle('body')
  }

  return (
    <Fab
      color="primary"
      aria-label="theme"
      onClick={themeHandle}
    >
      {isLit ? <Brightness7Icon /> : <Brightness3Icon />}
    </Fab>
  )
}

export default ThemeToggle
