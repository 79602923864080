import React from "react"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"

import { NavMenu, NavItem } from "@mui-treasury/components/menu/navigation"
import { useLineNavigationMenuStyles } from "@mui-treasury/styles/navigationMenu/line"

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"

import Brand from "../Brand"
import SideDrawer from "./SideDrawer"

import useStyles from "./styles"

const defaultProps = {
  navLinks: [{ title: `home`, path: `/` }],
}
type Props = {
  navLinks: []
} & typeof defaultProps

const NavBar = ({ navLinks }: Props): ReactElement => {
  const classes = useStyles()
  const location = useLocation()
  console.log(location.pathname)
  return (
    <Grid container item alignItems="center" className={classes.navbar}>
      <Grid item xs={8} md={3} xl={2}>
        <Box className={classes.logo}>
          <Brand />
        </Box>
      </Grid>
      <Grid item xs={1} lg={8}>
        <Hidden mdDown>
          <NavMenu
            className={classes.links}
            useStyles={useLineNavigationMenuStyles}
          >
            {navLinks.map(({ index, title, path }) => (
              <NavItem
                key={index}
                active={location.pathname === path ? true : false}
                className={classes.linkText}
              >
                <Box onClick={() => navigate(path)}>
                  <Typography variant="h5">{title}</Typography>
                </Box>
              </NavItem>
            ))}
          </NavMenu>
        </Hidden>
      </Grid>
      <Hidden lgUp>
        <SideDrawer navLinks={navLinks} color="black" />
      </Hidden>
    </Grid>
  )
}

export default NavBar

NavBar.defaultProps = defaultProps
