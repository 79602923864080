import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import SEO from '../SEO'
import NavBar from '../NavBar'
import ThemeToggle from '../ThemeToggle'
import {Typography} from '@material-ui/core'
interface LayoutProps {
  children: React.ReactNode
}

const navLinks = [
  { index: 0, title: `home`, path: `/` },
  { index: 1, title: `projects`, path: `/projectList` },
  { index: 2, title: `products`, path: `/productList` },
  { index: 3, title: `about us`, path: `/about` },
  { index: 4, title: `contact`, path: `/contact` },
]

const Layout = ({ children }: LayoutProps) => {
  const data: any = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { templateKey: { eq: "about" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              companyContact {
                phone
              }
            }
          }
        }
      }
      site(siteMetadata: { title: {} }) {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle: string =
    data.site.siteMetadata?.title || `Title`
  console.log(data)

  return (
    <>
      <SEO />
      <Box
        id="root"
        display="flex"
        width="100%"
        justifyContent="space-around"
        alignItems="center"
      >
        <Box
          display="flex"
          width="100%"
          flexDirection="column"
          minWidth={270}
          maxWidth={1920}
        >
          <Grid container item justify="flex-end" alignItems="center">
            <Grid item sm={10} alignContent="center">
              <Box mr={["1rem","2rem", "3rem"]}>
              <Typography variant="h5" align="right">
                {data.allMarkdownRemark.edges[0].node.frontmatter.companyContact.phone}
                </Typography>
                </Box>
            </Grid>
            <Grid item sm={1} alignContent="center">
              <ThemeToggle />
            </Grid>
          </Grid>
          <NavBar navLinks={navLinks} />
          <main>{children}</main>
        </Box>
      </Box>
    </>
  )
}

export default Layout
