import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() =>
  createStyles({
    navbar: {
      padding: "0 1rem 1rem 1rem",
      justifyContent: "space-between",
    },
    links: {
      float: "right",
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
    },
    logo: {
      width: "100%",
    },
  })
)

export default useStyles
